import React, { useRef, useEffect, useState } from "react";
import "../assets/styles/table.css";
import "../assets/styles/dialouge.css";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from "primereact/progressspinner";
import SignatureCanvas from "react-signature-canvas";
import { SERVICE_LOCATION, SITE_ATTENTION_MEASURES_BY_CLIENTACCOUNT, DOWNLOAD_SUBMITTED_WORKSHEET, SIGN_TO_APPROVE } from "./../constants/endpoints";
import { useCallback } from "react";

const SiteAttentionList = () => {
    const location = useLocation();
    const toast = useRef(null);

    const { data } = location.state !== null ? location.state : "";

    const [siteAttentionMeasures, setSiteAttentionMeasures] = useState(null);
    const [selectedMeasure, setSelectedMeasure] = useState(null);
    const [showPdf, setShowPdf] = useState(false);
    const [showSignatureDialouge, setShowSignatureDialouge] = useState(false);
    const [base64Pdf, setBase64Pdf] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [loading, setLoading] = useState(false);

    const signatureRef = useRef(null);

    const getSiteAttentionListByClientAccount = useCallback((clientAccount) => {
        const endPoint = SERVICE_LOCATION + SITE_ATTENTION_MEASURES_BY_CLIENTACCOUNT;
        const requestedData = {
            clientAccount: clientAccount
        }
        axios.post(endPoint, requestedData, {
            headers: {
                Authorization: `Bearer ${data.data.token}`,
            },
        })
            .then((response) => {
                setShowPdf(false);
                setShowSignatureDialouge(false);
                if (response.data.isSuccess) {
                    const siteAttentionMeasures = response.data.data.getSiteMeasures;
                    var allSiteAttentionMeasures = siteAttentionMeasures.map(function (item) {
                        const calloffRequestedDateOnly = (item.calloffRequested).substring(0, (item.calloffRequested).indexOf('T'));
                        const measureCompletedDateOnly = (item.measureCompleted).substring(0, (item.measureCompleted).indexOf('T'));
                        return { ...item, calloffRequested: calloffRequestedDateOnly === "1900-01-01" ? "" : calloffRequestedDateOnly, measureCompleted: measureCompletedDateOnly === "1900-01-01" ? "" : measureCompletedDateOnly };
                    });
                    setSiteAttentionMeasures(allSiteAttentionMeasures);
                }
                else if (!response.data.isSuccess) {
                    setSiteAttentionMeasures(null);
                }
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error, life: 3000 });
            })
    }, [data.data.token])

    const handleShowSiteAttentionMeasure = () => {
        setShowPdf(false);
    }

    const getFormattedDate = (dateToFormat) => {
        const year = dateToFormat.getFullYear();
        const month = String(dateToFormat.getMonth() + 1).padStart(2, "0");
        const day = String(dateToFormat.getDate()).padStart(2, "0");
        const formatedDate = `${year}-${month}-${day}`;
        return formatedDate;
    }

    const onModalSubmit = () => {
        var base64SignatureString = "";
        if (signatureRef.current.isEmpty()) {
            toast.current.show({ severity: "error", summary: "Error", detail: "Please provide a signature before saving.", life: 3000 });
        }
        else {
            var signatureText = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
            const splitArray = signatureText.split("data:image/png;base64,");
            if (splitArray.length === 2) {
                base64SignatureString = splitArray[1];
            }
            const formatedDate = getFormattedDate(selectedDate);
            const endPoint = SERVICE_LOCATION + SIGN_TO_APPROVE;
            const requestedData = {
                submissionId: selectedMeasure.submissionId,
                managerName: data.data.clientDetails.userDisplayName,
                signedDate: formatedDate,
                base64SignatureString: base64SignatureString
            }
            axios.post(endPoint, requestedData, {
                headers: {
                    Authorization: `Bearer ${data.data.token}`,
                },
            })
                .then((response) => {
                    if (response.data.isSuccess) {
                        onModalClear();
                        getSiteAttentionListByClientAccount(data.data.clientDetails.clientAccount);
                        var successMsg = "Approved sleected measure";
                        toast.current.show({ severity: "success", summary: "Success", detail: successMsg, life: 3000 });
                    }
                    else if (!response.data.isSuccess) {
                        toast.current.show({ severity: "error", summary: "Error", detail: response.data.errorMessage, life: 3000 });
                    }
                })
                .catch((error) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: error, life: 3000 });
                })
        }
    }

    const approveWorkcard = (siteAttentionData) => {
        setLoading(true);
        const today = new Date();
        setMinDate(today);
        setSelectedMeasure(siteAttentionData);
        var selectedSubmissions = []
        const submission = {
            submissionId: siteAttentionData.submissionId
        }
        selectedSubmissions.push(submission);

        const requestedData = {
            isApproveForm: true,
            getWorkCardsRequestDTO: selectedSubmissions
        }
        const endPoint = SERVICE_LOCATION + DOWNLOAD_SUBMITTED_WORKSHEET;
        axios.post(endPoint, requestedData, {
            headers: {
                Authorization: `Bearer ${data.data.token}`,
            },
        })
            .then((response) => {
                setLoading(false);
                if (response.data.isSuccess) {
                    if (response.data.data.downloadedWorkCards.length > 0) {
                        (response.data.data.downloadedWorkCards).forEach(workcard => {
                            setBase64Pdf(workcard.workCardBase64String);
                            setShowPdf(true);
                            setShowSignatureDialouge(false);
                        });
                    }
                }
                else if (!response.data.isSuccess) {
                    toast.current.show({ severity: "error", summary: "Error", detail: response.data.errorMessage, life: 5000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error, life: 5000 });
            })
    }

    const onModalClear = () => {
        signatureRef.current.clear();
        setSelectedDate(null);
    }

    const onModalClose = () => {
        onModalClear();
        setShowSignatureDialouge(false);
    }

    const showSignToApprove = () => {
        setShowSignatureDialouge(true);
    }

    const handleApproveLink = (rowdata) => {
        return <Button label="Approve" onClick={() => approveWorkcard(rowdata)} link style={{ fontSize: "12px", paddingLeft: "0px" }} />
    }

    const handleDateChange = (e) => {
        const dateSelected = e.value;
        setSelectedDate(dateSelected);
    }

    useEffect(() => {
        getSiteAttentionListByClientAccount(data.data.clientDetails.clientAccount);
    }, [data.data.clientDetails.clientAccount, getSiteAttentionListByClientAccount]);

    // Search functionality
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        plotNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        worksheetCode: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const renderHeader = () => {
        return (
            <div className="row">
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="flex">
                        <h4>Site Attention Measures</h4>
                    </div>
                </div>
                <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <div className="flex justify-content-end txt-right">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = renderHeader();
    // Search functionality Ends here

    const footerContent = (
        <div>
            <Button label="Clear" size="small" onClick={onModalClear} severity="secondary" outlined />
            <Button label="Cancel" size="small" onClick={onModalClose} severity="secondary" outlined />
            <Button label="Approve" size="small" onClick={onModalSubmit} outlined />
        </div>
    );

    return (
        <div className={`app ${loading ? "disabled" : ""}`}>
            {
                loading &&
                <div className="spinner-overlay">
                    <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            }

            <Toast ref={toast} />
            <div className="body">
                {showPdf ?
                    <div>
                        <div className="row">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Button id="show-siteattention-measures" label="Back" link style={{ fontSize: "12px", paddingLeft: "0px" }} onClick={handleShowSiteAttentionMeasure} />
                            </div>
                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 txt-right padb-10">
                                <Button id="add-fitter" label="Sign to Approve" size="small" outlined onClick={showSignToApprove} />
                            </div>
                        </div>

                        <div>
                            <iframe title="PDF Viewer" width="100%" height="500px" src={`data:application/pdf;base64,${base64Pdf}`} />
                        </div>

                        <Dialog header="SITE AGENT DECLARATION" style={{ width: '50vw' }} visible={showSignatureDialouge} onHide={onModalClose} footer={footerContent}>
                            <div className="signature-dialouge">
                                <div className="row">
                                    <lable className="col-12 border-col worksheet-field height-30">I confirm that the above work has been completed to my satisfaction.</lable>
                                </div>

                                <div className="row">
                                    <label className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 border-col pad-center worksheet-field">Site Agent Name</label>
                                    <label className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 border-col pad-center worksheet-value">{data.data.clientDetails.userDisplayName}</label>
                                    <label className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 border-col pad-center worksheet-field">Date</label>
                                    <Calendar className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 worksheet-field pad-0" value={selectedDate} onChange={handleDateChange} minDate={minDate} showIcon dateFormat="dd/mm/yy" />
                                </div>
                                <div className="row">
                                    <label className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 border-col pad-center worksheet-field">Site Agent Signature</label>
                                    <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 border-col">
                                        <SignatureCanvas ref={signatureRef} penColor="black" canvasProps={{ width: 700, height: 90, className: "sigCanvas" }} />
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div> :
                    <div>
                        <DataTable className="font-12" value={siteAttentionMeasures} dataKey="id"
                            paginator rows={10} rowsPerPageOptions={[10, 20, 50]} filters={filters} header={header}
                            globalFilterFields={["id", "plotId", "submissionId", "plotNumber", "plotDescription", "measureDescription", "measureQuantity",
                                "worksheetCode", "locationCode", "totalRevenue", "payyAppNo", "measureStatus", "measureContractNumber",
                                "calloffRequested", "measureCompleted"]}
                            scrollable tableStyle={{ minWidth: "70rem" }} emptyMessage="No site attention measures available for the client.">
                            <Column header="Approve" body={handleApproveLink} />
                            <Column field="id" header="Id" className="table-header" hidden></Column>
                            <Column field="plotId" header="PlotId" className="table-header" hidden></Column>
                            <Column field="submissionId" header="Submission Id" className="table-header" hidden></Column>
                            <Column field="plotNumber" header="Plot Number" className="table-header"></Column>
                            <Column field="plotDescription" header="Plot Description" className="table-header"></Column>
                            <Column field="measureDescription" header="Measure Description" className="table-header"></Column>
                            <Column field="measureQuantity" header="Measure Quantity" className="table-header"></Column>
                            <Column field="worksheetCode" header="Worksheet Code" className="table-header"></Column>
                            <Column field="locationCode" header="Location" className="table-header"></Column>
                            <Column field="totalRevenue" header="Measure Price" className="table-header"></Column>
                            <Column field="payyAppNo" header="App No" className="table-header"></Column>
                            <Column field="measureStatus" header="Status" className="table-header"></Column>
                            <Column field="measureContractNumber" header="OrderNumber" className="table-header"></Column>
                            <Column field="calloffRequested" header="Calloff Requested" className="table-header"></Column>
                            <Column field="measureCompleted" header="Completed Date" className="table-header"></Column>
                        </DataTable>
                    </div>
                }
            </div>
        </div>
    )
}

export default SiteAttentionList;